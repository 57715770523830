import { defineStore } from "pinia";
// project imports
import type { blogpostType } from "@/types/BlogTypes";

interface blogTypeDe {
  blogposts: blogpostType[];
  recentPosts: blogpostType[];
  blogSearch: string;
  sortBy: string;
  selectedPost: blogpostType[] | any;
  filter: object;
}

export const useBlogStore = defineStore(
  "blog",
  () => {
    const state: blogTypeDe = reactive({
      blogposts: [],
      recentPosts: [],
      blogSearch: "",
      sortBy: "newest",
      selectedPost: [],
      filter: {
        mediaTypes: [],
        topic: null,
      },
    });

    return {
      ...toRefs(state),
    };
  },
  { persist: true },
);
